import { Product } from "../types";
import { useQuery } from "react-query";
import { products } from "../data/products";

export const getProducts = (): Promise<Product[]> => {
    return Promise.resolve(products);
}

export const useProducts = () => {
    return useQuery(
        ['products'], 
        () => getProducts(),
        { enabled: true }
    );
};
