import { Product } from "../types";

// TODO: Change this to a real API call
export const products: Product[] = [
    {
        slug: 'xill4',
        name: 'Xill4',
        description: "The Xill 4 is the industry's most advanced low-code and data-driven content migration platform. That enables us to co-create and perform (complex) migrations in a fast, reliable, compliant and cost-effective way.",
        link: "/products/xill4",
        keywords: [
            "migration builder", "xill", "xill3", "xill4"
        ],
        links: {
            documentation: "https://docs.xill.io/",
            releaseLatest: "https://dl.cloudsmith.io/s4ithSLtzxTVODZ7/xillio/xill4/raw/names/Xill4/versions/latest/Xill4.zip",
            accelerators: "/accelerators"
        },
    },
    {
        slug: 'link-redirector',
        name: 'Link Redirector',
        description: "Migrate without obsctructions",
        link: "/products/link-redirector",
        keywords: [
            "link redirector", "broken links"
        ],
        links: {
            documentation: "https://docs.xill.io/link/docs/introduction",
            releaseLatest: "https://docs.xill.io/link/releases"
        },
    }
]
