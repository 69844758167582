import React, { PropsWithChildren } from 'react';
import { AppProvider } from './providers/app';
import { AppRoutes } from './routes';

// export const App: React.FC<PropsWithChildren> = ({ children }) => {
//   return (
//     <div className="App">
//       <nav>
//         {/* Navigation bar */}
//       </nav>
//       {children}
//     </div>
//   );
// }

const App = () => {
  return <AppProvider>
    <AppRoutes />
  </AppProvider>
}

export default App;
