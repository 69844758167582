import { Accelerator, AcceleratorFilterOptions } from "../types";
import { useQuery, UseQueryOptions } from "react-query";
import { useLicense } from "../../../providers/license";

export const getAccelerators = async (
  license: string,
  filterOptions?: AcceleratorFilterOptions
): Promise<Accelerator[]> => {
  let url = "/api/accelerators";

  if (filterOptions) {
    const { searchTerm, selectedAuthor, selectedType } = filterOptions;
    const queryParams = new URLSearchParams();

    if (searchTerm) {
      queryParams.append("searchTerm", searchTerm);
    }

    if (selectedAuthor) {
      queryParams.append("author", selectedAuthor);
    }

    if (selectedType) {
      queryParams.append("type", selectedType);
    }

    if (queryParams.toString()) {
      url += `?${queryParams.toString()}`;
    }
  }

  const response = await fetch(url, {
    headers: {
      Authorization: "License " + license,
    },
  });

  if (!response.ok) {
    throw new Error(response.status + " " + response.statusText);
  }

  const json = await response.json();
  return json.accelerators;
};

export const useAccelerators = (
  options: Partial<{ useErrorBoundary: boolean }> = {},
  filterOptions?: AcceleratorFilterOptions
) => {
  const license = useLicense();

  return useQuery(
    ["accelerators", filterOptions],
    () => getAccelerators(license as string, filterOptions),
    { enabled: true, ...options }
  );
};
