import React from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from 'react-markdown'
import { useLicense } from "../../../providers/license";

type Props = {
};

export const Readme: React.FC<Props> = () => {
    const license = useLicense();
    const [readme, setReadme] = React.useState<string | null>(null);

    const getReadme = async (id: string) => {
        const response = await fetch(`/api/accelerators/${id}/readme`, {
            headers: {
                'Authorization': 'License ' + license
            }
        });
        if (!response.ok) {
            alert("Error accessing readme");
            return;
        }


        const readme = await response.text();
        setReadme(readme);
    }
    const { acceleratorId } = useParams();

    //returns a div with the acceleratorId
    getReadme(acceleratorId!);

    if (!readme) {
        return null;
      }

    return readme? 
    <div className="card" ><ReactMarkdown >{readme}</ReactMarkdown></div>:null
};

