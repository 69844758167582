import { Card } from "../../../components/Card"
import { SearchBar } from "../components/SearchBar";
import { shortcuts } from "../data/shortcuts";

export const Landing: React.FC = () => {
    return <><div className="hero">
        <h1>Xillio Community</h1>
        <p>Xillio will always deliver, due to it's amazing community!</p>
        <SearchBar />
    </div>
        <div className="content">
            <div className="section shortcuts">
                {
                    shortcuts.map((shortcut, index) =>!shortcut.hidden && <Card key={index} {...shortcut}/>)
                }
            </div>
        </div>
    </>
}
