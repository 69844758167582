import * as React from "react"
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools'
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";
import { queryClient } from "../lib/react-query";
import { LicenseProvider } from "./license";

export const AppProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return <React.Suspense
    fallback={<Fallback />}>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <LicenseProvider>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <BrowserRouter>
            {children}
          </BrowserRouter>
        </QueryClientProvider>
      </LicenseProvider>
    </ErrorBoundary>
  </React.Suspense>
}

const Fallback = () => {
  return <>loading</>;
}

const ErrorFallback: React.FC<FallbackProps> = ({ error }) => {
  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
      <p>{error.message}</p>
      <button className="mt-4" onClick={() => window.location.assign(window.location.origin)}>
        Refresh
      </button>
    </div>
  );
};
